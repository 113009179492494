import React, {Component} from 'react'
import AuthUserContext from './AuthUserContext';
import Select from 'react-select'
import {db, auth} from '../../firebase/';
import * as routes from '../../constants/routes';
import '../../Content/css/trial.css';
import {navigate} from 'gatsby';
import countryList from 'react-select-country-list'
// sign in logos
import FacebookLogo from '../../Content/images/logos/facebook.png';
import MailLogo from '../../Content/images/logos/mail.png';
import GoogleLogo from '../../Content/images/logos/google.png';
import '../../Content/css/authentication.css';
import DesktopLanding from '../../Content/images/Trial/hero.jpg';
import HtmlParser from 'react-html-parser';
import {connect} from "react-redux";
import {storeUser, addNotification} from "../../redux/actions/";
import Helmet from 'react-helmet';
import {Event} from '../Analytics/';
import firebase from "gatsby-plugin-firebase";
let gravURL = process.env.REACT_APP_GRAV_URL;
var validator = require("email-validator");

const byPropKey = (propertyName, value) => () => ({[propertyName]: value});
const INITIAL_STATE = {
  options: countryList().getData(),
  username: '',
  email: '',
  passwordOne: '',
  passwordTwo: '',
  error: null,
  date: new Date().toLocaleString(),
  timestamp: Date.now(),
  premium: 'false',
  passwordWarning: "Passwords don't match",
  passwordLengthWarning: 'Must be 6 characters or more',
  country: null,
  marketing: 'true',
  countryInValid: false,
  usernameInvalid: false,
  emailInvalid: false,
  errorCountryMsg: '',
  errorUsernameMsg: '',
  verified: false,
  verificationMessage: '',
  userCode: 'TRIAL',
  skipVerification: false,
  size: '',
  headContent: {},
  mobileWrapperStyle: {},
  formOpen: false,
  trialPoints: ['Enjoy Hundreds Of Delicious Keto Recipes', 'Create Personalised Meal Plans', 'Keto Calculator', 'Easy To Use Online Tracking Tools', 'Daily Online Journal ']
};
export default class TrialPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...INITIAL_STATE
    }
  }
  toggleSignUpForm() {
    let status = this.state.formOpen;
    this.setState({
      formOpen: !status
    });
  }

  handleEmail(e) {

    this.setState({email: e.target.value})
    if (e.target.value.length > 0) {
      this.setState({errorEmailMsg: '', emailInvalid: false})
    }
  }
  handleUsername(e) {
    const name = e.target.value;
    this.setState({username: name})
    if (name.length > 0) {
      this.setState({errorUsernameMsg: '', usernameInvalid: false})
    }
  }
  handlePassword(e) {
    const password = e.target.value;
    this.setState({passwordOne: e.target.value})
    if (password.length > 5) {
      this.setState({errorPasswordMsg: ''})
    } else {
      this.setState({errorPasswordMsg: this.state.passwordLengthWarning})
    }
  }
  changeCountry = (chosenCountry) => {
    this.setState({country: chosenCountry, countryInvalid: false, errorCountryMsg: ''})
  }
  handleSkip() {
    this.setState({skipVerification: true, verified: true, trial: true})
  }
  verifyUser(code) {
    let verified = false;
    for (let i = 0; this.state.codes.length > i; i++) {
      if (code === this.state.codes[i].code) {
        verified = true;

      }

    }
    if (verified === true) {
      this.setState({verified: true, verificationMessage: 'Access Granted', userCode: code, trial: false})
    } else {
      this.setState({verificationMessage: 'Invalid Code'})
    }
  }
  validateForm() {
    let valid = this.state.country !== null && this.state.username.length > 0 && this.state.email.length > 0 && this.state.passwordOne.length > 5 && this.state.passwordTwo === this.state.passwordOne && validator.validate(this.state.email) === true;
    if (valid) {
      this.onSubmitSelfSignup();
      this.setState({error: null})
    }
    let errors = [];
    if (this.state.username.length === 0) {
      this.setState({usernameInvalid: true});
      errors.push('<div>Name is required</div>')
    }
    if (validator.validate(this.state.email) === false) {
      this.setState({emailInvalid: true})
      errors.push('<div>Email is badly formatted</div>');
    }
    if (this.state.country === null) {
      this.setState({countryInvalid: true})
      errors.push('<div>Country is required</div>');
    }
    if (this.state.email.lenth === 0) {
      this.setState({emailInvalid: true})
      errors.push('<div>Email is required</div>')
    }
    if (this.state.email.lenth === 0) {
      this.setState({emailInvalid: true})
      errors.push('<div>Email is required</div>')
    }
    if (this.state.passwordOne.length > 6) {
      errors.push(this.state.passwordLengthWarning)
    }
    if (this.state.passwordOne !== this.state.passwordTwo) {
      errors.push('<div>Passwords do not match</div>')
    }
    this.setState({
      error: {
        message: HtmlParser(errors.join(' '))
      }
    })
  }
  getTrialEndDate(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    const timestamp = +new Date(result);
    return timestamp;
  }
  addUserTrial(user) {
    let timestamp = +new Date();
    user.trial = true;
    user.premium = true;
    user.userCode = 'TRIAL';
    user.trialEnd = this.getTrialEndDate(timestamp, 14);
    user.verified = true;
    return user;
  }
  handleFacebookSignin() {
    const facebookProvider = new firebase
      .auth
      .FacebookAuthProvider();
    console.log('PROVIDER', facebookProvider)
    firebase
      .auth()
      .signInWithPopup(facebookProvider)
      .then((userData) => {
        const user = this.addUserTrial(userData.user);
        db.storeSocialLoginFromTrial(user, 'Facebook');
        Event("New User", "Started Trial", `Facebook`);
        navigate(routes.TELLUSABOUTYOURSELF);
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });
  }
  handleGoogleSignin() {

    const googleProvider = new firebase
      .auth
      .GoogleAuthProvider();
    firebase
      .auth()
      .signInWithPopup(googleProvider)
      .then((userData) => {
        const user = this.addUserTrial(userData.user);
        console.log('USER DATA', user)
        db.storeSocialLoginFromTrial(user, 'Google');
        Event("New User", "Started Trial", `Google`);
        navigate(routes.TELLUSABOUTYOURSELF);
      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });
  }

  onSubmitSelfSignup() {
    const {
      username,
      email,
      passwordOne,
      country,
      marketing,
      verified,
      userCode
    } = this.state;
    let avatarLetter = String(username).slice(0, 1)
    let trialEndDate = new Date();
    trialEndDate.setDate(trialEndDate.getDate() + 14);

    auth
      .doCreateUserWithEmailAndPassword(email, passwordOne)
      .then(authUser => {
        const user = {
          id: authUser.user.uid,
          email,
          username,
          marketing,
          country,
          verified,
          userCode,
          avatarLetter,
          trial: true,
          trialEndDate
        }
        db.doCreateUserWithTrial(authUser.user.uid, user)
        Event("New User", "Started Trial", `Email`);
        navigate(routes.TELLUSABOUTYOURSELF);

      })
      .catch(error => {
        this.setState(byPropKey('error', error));
      });
  }
  closeTrialPromotion() {
    if (this.state.trialPopUp === true) {
      this.setState({trialPopUp: false})
    }
  }
  handleStartTrial() {
    if (this.state.trialPopUp === true) {
      this.setState({trialPopUp: false, verified: true, trial: true, skipVerification: true})
    }
  }
  componentDidMount() {
    this._isMounted = true
    if (this._isMounted) {
      window.scrollTo(0, 0);

      if (this.state.trialPromotion === true) {
        this.setState({trialPopUp: true})
      }

      let windowHeight = window.innerHeight;
      let wrapperStyle = {
        minHeight: (windowHeight - 50) + 'px'
      }
      let mobileWrapperStyle = {
        minHeight: (windowHeight - 50) + 'px'
      }

      this.setState({wrapperStyle, windowHeight, mobileWrapperStyle});
      this.updateWindowDimensions();
      window.addEventListener('resize', this.updateWindowDimensions.bind(this));
    }
  }
  updateWindowDimensions() {
    this.setState({windowWidth: window.innerWidth})

    this.setState({
      size: window.innerWidth >= 999
        ? 'large'
        : 'small',
      wrapperStyle: {
        minHeight: window.innerWidth >= 999
          ? (window.innerHeight - 65) + 'px'
          : (window.innerHeight - 65) + 'px'
      }
    })
  }
  render() {
    const {username, email, passwordOne, passwordTwo, error} = this.state, {location} = this.props,
      nameInvalid = this.state.usernameInvalid === true,
      emailInvalid = this.state.emailInvalid === true,
      countryInvalid = this.state.countryInvalid === true,
      passwordsDontMatch = passwordOne !== passwordTwo,
      formOpen = this.state.formOpen === true,
      title = 'Free Trial | New Image Keto',
      passwordsInvalid = passwordsDontMatch && passwordTwo.length !== 0,
      shareImg = `${gravURL}/uploads/images/trial.jpg`,
      description = `Make planning your keto programme simple! Take advantage of our 'locked subscriber only tools' for 14 days - free at New Image Keto`
    return (
      <main>
        <Helmet>
          <title>{title}</title>
          <meta name="description" content={description}/>
          <meta property="og:image:width" content="1200"/>
          <meta property="og:image:height" content="630"/> {location && <meta property="og:url" content={location.href}/>}
          <meta property="og:title" content={title}/>
          <meta name="twitter:title" content={title}/>
          <meta property="og:description" content={description}/>
          <meta name="twitter:description" content={description}/>
          <meta property="og:image" content={shareImg}/>
          <meta property="og:image:url" content={shareImg}/>
          <meta name="twitter:image" content={shareImg}/>
          <meta name="twitter:card" content={shareImg}/>
        </Helmet>
        <div
          className="bg-primary signupFrame signupFull lg:pt-10 relative"
          style={this.state.wrapperStyle}>
          <div className="w-full pageGrid">
            <div className="flex w-full flex-wrap">
              <div
                className="xl:w-5/12 lg:w-6/12 lg:absolute bottom-0 xl:bottom-0 left-0 z-10 md:w-7/12 mx-auto text-center">
                <img src={DesktopLanding} alt="New_Image™ Keto Trial"/>
              </div>
              <div className="xl:w-5/12 lg:w-6/12 w-full"></div>
              <div className="xl:w-7/12 lg:w-6/12 relative z-20 w-full signup-form-content">
                <div
                  className="signUpForm trialForm bg-white rounded-lg shadow-lg p-10 max-w-md mx-auto relative"
                  data-open={formOpen}>
                  <button
                    className="absolute top-0 right-0 mr-2 mt-2 cursor-pointer"
                    onClick={this
                    .toggleSignUpForm
                    .bind(this)}>
                    <span className="text-2xl text-primary flaticon-cancel"></span>
                  </button>
                  <h2 className="text-2xl text-secondary font-medium mb-4 text-center">Sign Up</h2>
                  <div className="my-2">
                    <label
                      className={nameInvalid
                      ? "text-red-600 block text-center mb-2 text-sm font-medium"
                      : "block text-center mb-2 text-sm font-medium"}>
                      Full Name
                      <input
                        value={username}
                        onChange={this
                        .handleUsername
                        .bind(this)}
                        type="text"
                        className={nameInvalid
                        ? "formControl is-invalid"
                        : "formControl"}
                        name="name"/>
                    </label>

                  </div>
                  <div className="my-2">
                    <label
                      className={emailInvalid
                      ? "text-red-600 block text-center mb-2 text-sm font-medium"
                      : "block text-center mb-2 text-sm font-medium"}>
                      Email
                      <input
                        value={email}
                        onChange={this
                        .handleEmail
                        .bind(this)}
                        type="text"
                        className={emailInvalid
                        ? "formControl is-invalid"
                        : "formControl"}
                        name="email"/>
                    </label>
                  </div>
                  <div className="my-2">
                    <label
                      className={passwordsInvalid
                      ? "text-red-600 block text-center mb-2 text-sm font-medium"
                      : "block text-center mb-2 text-sm font-medium"}>
                      Set Password
                      <input
                        value={passwordOne}
                        onChange={this
                        .handlePassword
                        .bind(this)}
                        type="password"
                        className={passwordsInvalid
                        ? "formControl my-2 is-invalid"
                        : "formControl my-2"}
                        name="setpassword"/>
                    </label>

                  </div>
                  <div className="my-2">
                    <label
                      className={passwordsInvalid
                      ? "block text-center mb-2 text-sm font-medium text-red-600"
                      : "block text-center mb-2 text-sm font-medium"}>
                      Repeat Password
                      <input
                        value={passwordTwo}
                        onChange={event => this.setState(byPropKey('passwordTwo', event.target.value))}
                        type="password"
                        name="password-two"
                        className={passwordsInvalid
                        ? "formControl my-2 is-invalid"
                        : "formControl my-2"}/>
                    </label>
                    {passwordsDontMatch && <div className="py-2 text-red-600 font-bold text-center"></div>
}
                  </div>
                  <div className="my-2">
                    <div
                      className={countryInvalid
                      ? "block text-center mb-2 text-sm font-medium text-red-600"
                      : "block text-center mb-2 text-sm font-medium"}>
                      Select your location
                      <Select
                        options={this.state.options}
                        value={this.state.country}
                        placeholder="Country"
                        name="country"
                        onChange={this
                        .changeCountry
                        .bind(this)}
                        className={countryInvalid
                        ? "countrySelect border border-red-600 rounded-md"
                        : "countrySelect"}/>
                    </div>
                  </div>

                  <div className="inputWrapper mt-8">
                    {error && <div
                      className="font-medium bg-red-600 text-white px-2 w-full mb-4 text-center text-sm font-medium py-1 rounded-lg">{error.message}</div>}
                    <button
                      onClick={this
                      .validateForm
                      .bind(this)}
                      type="submit"
                      className={"py-2 px-4 text-white w-full bg-primary rounded-lg text-white" + this.state.signUpButton}>
                      Sign Up
                    </button>
                  </div>
                  <br/>
                </div>
                <div
                  className="trialForm p-5 lg:px-10 w-full bg-white lg:bg-transparent md:-mt-20 -mt-10 lg:mt-0 md:w-10/12 mb-0 lg:mb-0 mx-auto"
                  data-open={!formOpen}>
                  <div className="lg:text-white xl:pt-0">
                    <h1
                      className="uppercase text-2xl md:text-3xl xl:text-5xl lg:text-4xl font-bold xl:-ml-64 lg:-ml-32 lg:mb-4">Start your free 14 day trial!</h1>
                    <div className="mb-4 lg:-ml-16 xl:-ml-32">
                      <h2 className="text-1xl font-light uppercase">Make planning your keto programme simple!</h2>
                      <p className="text-1xl font-medium">
                        Take advantage of our
                        <span className="uppercase">"Locked subscriber only tools"
                        </span>
                        <strong>for 14 days -
                          <span className="uppercase mr-2">FREE</span>
                        </strong>
                        <small className="font-normal text-xs whitespace-no-wrap">
                          (no credit card required)</small>
                      </p>
                    </div>
                    <div className="lg:-ml-8 xl:-ml-16 trial-points">
                      <div className="mb-4">
                        {this
                          .state
                          .trialPoints
                          .map((point, key) => <div className="my-2" key={key}>
                            <span className="flaticon-tick"></span>
                            {point}
                          </div>)}
                      </div>
                      <h2 className="uppercase font-bold mb-4 lg:text-2xl">Keto Made Easy with New Image™ Keto</h2>

                      <AuthUserContext.Consumer>
                        {authUser => authUser
                          ? <div className="mb-10">
                              <Button uid={authUser.uid} history={this.props.history}/>
                            </div>
                          : <div>
                            <div className="my-2">
                              <button
                                className="bg-white text-secondary w-full lg:max-w-sm rounded-lg py-2"
                                onClick={this
                                .toggleSignUpForm
                                .bind(this)}>
                                <img
                                  src={MailLogo}
                                  className="socialSignInLogo"
                                  alt="Facebook Social Login Logo"/>
                                Signup with Email
                              </button>
                            </div>
                            <div className="my-2">
                              <button
                                className="facebookLoginButton w-full lg:max-w-sm rounded-lg py-2"
                                onClick={this
                                .handleFacebookSignin
                                .bind(this)}>
                                <img
                                  src={FacebookLogo}
                                  className="socialSignInLogo"
                                  alt="Facebook Social Login Logo"/>
                                Sign in with Facebook
                              </button>
                            </div>
                            <div className="my-2">
                              <button
                                className="googleLoginButton w-full lg:max-w-sm rounded-lg py-2"
                                onClick={this
                                .handleGoogleSignin
                                .bind(this)}>
                                <img
                                  src={GoogleLogo}
                                  className="socialSignInLogo"
                                  alt="Google Social Login Logo"/>
                                Sign in with Google
                              </button>
                            </div>
                          </div>
}
                      </AuthUserContext.Consumer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
  }
}

const mapStateToProps = (state, props) => {
  return {user: state.user, uid: state.uid, history: props.history};
};
const ButtonConnected = ({user, uid, addNotification, storeUser, history}) => (<UserTrialButton
  user={user}
  uid={uid}
  addNotification={addNotification}
  storeUser={storeUser}
  history={history}/>);
function mapDispatchToProps(dispatch) {
  return {
    storeUser: user => dispatch(storeUser(user)),
    addNotification: notification => dispatch(addNotification(notification))
  };
}
class UserTrialButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validationMessage: ''
    }
  }
  addTrialToUser(user) {
    this
      .props
      .addNotification('Free Trial Added');
    let trialEndDate = new Date();
    trialEndDate.setDate(trialEndDate.getDate() + 14);
    let trialEnd = +new Date(trialEndDate);
    const trialData = {
      verified: true,
      trialEnd: trialEnd,
      trial: true,
      premium: true,
      userCode: 'TRIAL'
    }
    db
      .storeTrialUserData(this.props.uid, trialData)
      .then(() => {
        db
          .getUserInfo(this.props.uid)
          .then((userData) => {
            this
              .props
              .storeUser(userData);
            if (user.stats) {
              this
                .props
                .navigate(routes.PROFILE);
            } else {
              this
                .props
                .navigate(routes.TELLUSABOUTYOURSELF);
            }
          })
      })
      .catch((e) => {
        console.log(e);
        this
          .props
          .addNotification('There was an error adding trial to your profile')
      })
  }
  checkUserElegibility(now, trialEnd, trial, verified) {
    if (verified === true) {
      this
        .props
        .addNotification('Your account is already unlocked')
      return false
    } else {
      if (trial === true || now > trialEnd) {
        this
          .props
          .addNotification('Your free trial period has already ended, you cannot add a further trial');
        return false
      }
      if (now < trialEnd) {
        this
          .props
          .addNotification('Your free trial is still active')
      }
      if (trialEnd === undefined) {
        return true
      }
    }
  }
  userStartTrial() {
    const {user} = this.props;
    const now = +new Date();
    const {trial, trialEnd, verified} = user;
    const elegible = this.checkUserElegibility(now, trialEnd, trial, verified);
    if (elegible) {
      this.addTrialToUser(user)
    }
  }
  render() {
    return (
      <div className="lg:max-w-sm">
        <button
          onClick={this
          .userStartTrial
          .bind(this)}
          className="w-full py-2 rounded-lg text-secondary bg-white lg:bg-primary trial-button">Start 14 Day FREE trial</button>
      </div>
    )
  }

}
export const Button = connect(mapStateToProps, mapDispatchToProps)(ButtonConnected);
